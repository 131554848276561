import React from "react";
import { Box, Grid, makeStyles } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

import { SkipNext } from "@material-ui/icons";
import OptionsGroup from "../../components/OptionsGroup";
import { incomeTypeOptions } from "../../../../commons/loan-application-constants";
import Button from "../../components/Button";
import { useStyles as useSectionStyles } from "../../../../utils/loan-application.styles";
import { setFieldTouchedRecusively } from "../../../../utils/loan-application.utils";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "50%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: "36px",
    },
  },
}));

const IncomeTypeStep = ({ formik, handleNextStep, handleSaveSection }) => {
  const classes = useStyles();
  const sectionClasses = useSectionStyles();

  const handleOnSelect = (value) => {
    if (value !== formik.values?.income?.incomeType) {
      formik.setFieldValue(`income`, formik.initialValues.income);
    }
    formik.setFieldValue(`income.incomeType`, value);
  };

  const onNextStep = async () => {
    if (!formik.values?.income?.incomeType) {
      await formik.setFieldValue("income", null);
      await handleSaveSection();
      return;
    }
    await setFieldTouchedRecusively(formik, "income", false, false);
    handleNextStep();
  };

  return (
    <Box classes={{ root: classes.container }}>
      <OptionsGroup
        selectedValue={formik.values?.income?.incomeType}
        onSelect={handleOnSelect}
        title="Income Type"
        options={incomeTypeOptions}
        error={
          formik.touched?.income?.incomeType &&
          Boolean(formik.errors?.income?.incomeType)
        }
        helperText={
          formik.touched?.income?.incomeType &&
          formik.errors?.income?.incomeType
        }
      />
      <Grid
        container
        xs={12}
        md={6}
        justifyContent="space-between"
        classes={{ root: sectionClasses.navigationButtonsContainer }}
      >
        <Button
          endIcon={
            formik.values?.income?.incomeType ? (
              <NavigateNextIcon />
            ) : (
              <SkipNext />
            )
          }
          onClick={onNextStep}
        >
          {formik.values?.income?.incomeType ? "Next" : "Skip"}
        </Button>
      </Grid>
    </Box>
  );
};

export default IncomeTypeStep;
